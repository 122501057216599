import { RootState } from 'configs/configureStore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const usePermission = (code: string | null) => {
  const [isNotAllowed, setIsAllowed] = useState(false);
  const { currentPagePermissions } = useSelector(
    (state: RootState) => state.globalSlice,
  );

  useEffect(() => {
    setIsAllowed(
      currentPagePermissions !== null
        ? currentPagePermissions.find(item => item.code === code)?.value ||
            false
        : false,
    );
  }, [code, currentPagePermissions]);

  return isNotAllowed;
};
